/**
 * 机器人js
 */

import axios from "@/resources/js/request";
import da from "element-ui/src/locale/lang/da";

const request = axios.request;

/**
 * 获取机器人信息
 * @returns {Promise<unknown>}
 */
export const getrobot = (data) => {
  return request.doPost('/robot/getrobot', data);
}

/**
 * 添加或者修改机器人信息
 * @returns {Promise<unknown>}
 */
export const addorupdateProject = (data) => {
  return request.doPost('/robot/addorupdaterobot', data);
}
/**
 * 获取机器人信息
 * @returns {Promise<unknown>}
 */
export const getrobotinfo = (data) => {
  return request.doPost('/robot/getrobotinfo', data);
}
/**
 * 自动回复
 * @returns {Promise<unknown>}
 */
export const getAutoReply = (data) => {
  return request.doPost('/robot/getAutoReply', data);
}
/**
 * 添加留言
 * @returns {Promise<unknown>}
 */
export const addLeaveMessage = (data) => {
  return request.doPost('/robot/addLeaveMessage', data);
}

