/**
 * websocke的js
 */
import axios from "@/resources/js/request";

const request = axios.request;
/**
 * 获取机器人信息
 * @returns {Promise<unknown>}
 */
export const sendOneWebSocket = (data) => {
  return request.doPost('/websocket/sendOneWebSocket', data);
}
/**
 * 获取用户的sessionid
 * @returns {Promise<unknown>}
 */
export const getSessionId = (data) => {
  return request.doPost('/websocket/getSessionId', data);
}
/**
 * 获取在线的客服,并且创建连接表
 * @returns {Promise<unknown>}
 */
export const getOnLineService = (data) => {
  return request.doPost('/websocket/getOnLineService', data);
}
/**
 * 修改连接状态
 * @returns {Promise<unknown>}
 */
export const updatelinestatus = (data) => {
  return request.doPost('/websocket/updatelinestatus', data);
}

/**
 * 更改消息已读
 * @returns {Promise<unknown>}
 */
export const updatemsgread = (data) => {
  return request.doPost('/websocket/updatemsgread', data);
}
/**
 * 客服工作台数据更新
 * @returns {Promise<unknown>}
 */
export const getDataList = (data) => {
  return request.doPost('/websocket/getDataList', data);
}
/**
 * 获取历史连接记录
 * @returns {Promise<unknown>}
 */
export const getHisoryConnectList = (data) => {
  return request.doPost('/websocket/getHisoryConnectList', data);
}
/**
 * 根据连接获取通信记录
 * @returns {Promise<unknown>}
 */
export const queryallMsg = (data) => {
  return request.doPost('/websocket/queryallMsg', data);
}

/**
 * 获取客服未处理消息
 * @returns {Promise<unknown>}
 */
export const querynotdealMsg = (data) => {
  return request.doPost('/websocket/querynotdealMsg', data);
}

/**
 * 刷新客服心跳时间
 * @returns {Promise<unknown>}
 */
export const refreshcsheartbeat = (data) => {
  return request.doPost('/websocket/refreshcsheartbeat', data);
}

/**
 * 刷新用户心跳时间
 * @returns {Promise<unknown>}
 */
export const refreshclientheartbeat = (data) => {
  return request.doPost('/websocket/refreshclientheartbeat', data);
}

/**
 * 客服断开连接
 * @returns {Promise<unknown>}
 */
export const csDisconnect = (data) => {
  return request.doPost('/websocket/csDisconnect', data);
}

/**
 * 保存智能客服消息
 * @returns {Promise<unknown>}
 */
export const saveRobotMessage = (data) => {
  return request.doPost('/websocket/saveRobotMessage', data);
}

/**
 * 地址栏参数解码
 * @returns {Promise<unknown>}
 */
export const decryptTokenkey = (data) => {
  return request.doPost('/websocket/decryptTokenkey', data);
}

/**
 * 保存用户信息
 * @returns {Promise<unknown>}
 */
export const addOrUpdateUserInfo = (data) => {
  return request.doPost('/websocket/addOrUpdateUserInfo', data);
}

/**
 * 添加投诉
 * @returns {Promise<unknown>}
 */
export const addComplaint = (data) => {
  return request.doPost('/websocket/addComplaint', data);
}

/**
 * 添加评价
 * @returns {Promise<unknown>}
 */
export const addClientEvaluate = (data) => {
  return request.doPost('/websocket/addClientEvaluate', data);
}

/**
 * 获取项目设置
 * @returns {Promise<unknown>}
 */
export const getConfigset = (data) => {
  return request.doPost('/websocket/getConfigset', data);
}

/**
 * 保存智能模式下的聊天记录
 * @returns {Promise<unknown>}
 */
export const saveRobotMsgReload = (data) => {
  return request.doPost('/websocket/saveRobotMsgReload', data);
}

/**
 * 外部接入获取用户信息
 * @returns {Promise<unknown>}
 */
export const getUserInfo = (data) => {
  return request.doPost('/websocket/getUserInfo', data);
}

/**
 * 获取自动获取发送消息
 * @returns {Promise<unknown>}
 */
export const getAutoreply = (data) => {
  return request.doPost('/websocket/getAutoreply', data);
}
/**
 * 刷新已读未读字段
 * @param data
 */
export const freshAdminReadFlag = (data) =>{
  return request.doPost('/websocket/freshAdminReadFlag', data);
}
