<template>
  <el-container>
    <el-header class="header" :style="{backgroundColor: configset.framecolor}">
      <div class="flex flex-sb font-20" style="color: white">
        <div>
          <i class="el-icon-chat-dot-square iconft"></i>
          <span>我要留言</span>
        </div>
        <div class="flex">
          <div>
            <i class="el-icon-user iconft"></i>
            <span><a @click="toclient" target="_blank">人工服务</a></span>
          </div>
          <div style="margin-left: 20px;">
            <i class="el-icon-s-custom iconft"></i>
            <span><a @click="toRobot" target="_blank">自助咨询</a></span>
          </div>
          <div style="margin-left: 20px;color: #ff9900">
            <i class="el-icon-chat-dot-square iconft"></i>
            <span><a @click="LeavingMessage" target="_blank">我要留言</a></span>
          </div>
        </div>
      </div>
    </el-header>
    <el-container style="background-color: white">
      <el-form ref="dataForm" :rules="rules" :model="temp" label-position="left" label-width="100px"
               style="width: 500px; margin-left:calc((100% - 500px) /2);margin-top:10%; ">
        <el-form-item prop="content">
          <el-input v-model="temp.content" type="textarea" placeholder="请在此输入留言内容，我们会尽快与您联系。（必填）"/>
        </el-form-item>
        <el-form-item prop="username">
          <el-input v-model="temp.username" type="text" placeholder="联系人（必填）"/>
        </el-form-item>
        <el-form-item prop="mobile">
          <el-input v-model="temp.mobile" type="text" placeholder="电话（选填）"/>
        </el-form-item>
        <el-form-item prop="email">
          <el-input v-model="temp.email" type="text" placeholder="邮箱/QQ号码/微信"/>
        </el-form-item>
        <div style="text-align: center;margin: 20px 0;font-size: 16px;color: #64ea04">注：留下联系方式以便客服能及时联系，解决您的问题</div>
          <el-button type="primary" style="margin-left: 250px;" @click="createData()">
            提交
          </el-button>
      </el-form>
    </el-container>
  </el-container>
</template>

<script>
  import {addLeaveMessage} from "@/apis/robot";
  import {decryptTokenkey,getConfigset} from "@/apis/webstocket";
  import asyncValidator from "@/resources/js/async-validator";

  export default {
    name: "index",
    data() {
      var checkPhone = (rule, value, callback) => {
        const phoneReg = /^1[3|4|5|6|7|8][0-9]{9}$/
        // 当数据为空时，不进行校验
        if (!value) {
          return callback()
        }
        setTimeout(() => {
          if (phoneReg.test(value)) {
            callback()
          } else {
            callback(new Error('请输入正确的手机号码'))
          }
        }, 100)
      }
      return {
       temp: {
          projid: this.$route.query.projid,
          mobile: '',
          email: '',
          qq: '',
          username: '',
          content: ''
        }, rules: {
          mobile: [{required: false, validator:checkPhone , trigger:  'blur'}],
          email: [{required: true,message: '请输入正确的邮箱', trigger:  'blur'}],
          content: [{required: true, message: '请输入留言内容', trigger:  'blur'}],
          username: [{required: true, message: '请输入姓名', trigger:  'blur'}],
        },configset:{
          framecolor:null
        },
      }
    },
    mounted() {
      this.decryptTokenkey();
    },
    methods: {
      resetTemp() {
        this.temp = {
          projid: "",
          mobile: '',
          email: '',
          username: '',
          qq: '',
          content: ''
        }
      },createData() {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            addLeaveMessage(this.temp).then(res => {
              this.$message.success(res.retDesc);
              this.resetTemp();
            });
          }
        })
      }, toclient() {
        this.$router.push("/client?tokenkey=" + this.getEcodeUrl(this.$route.query.tokenkey));
      } , toRobot() {
        this.$router.push("/vclient/robot?tokenkey=" + this.getEcodeUrl(this.$route.query.tokenkey));
      },leavingMessage() {
        this.$router.push("/leavingMessage?tokenkey=" + this.getEcodeUrl(this.$route.query.tokenkey));
      },decryptTokenkey() { //地址栏参数解码
        let data = {
          tokenkey: this.$route.query.tokenkey
        }
        decryptTokenkey(data).then(res => {
          this.temp.projid = res.retdata.projid;
          this.getConfigset();
        })
      },getConfigset() {
      let data = {
        projid: this.temp.projid
      }
      getConfigset(data).then(res => {
        this.configset = res.retdata;
      })
    },getEcodeUrl(url){
      if (url) {
        // url地址栏中特殊字符转义
        url = url
        .replace(/\=/g, "%3D")
        .replace(/\+/g, "%2B")
        .replace(/[\s]/g, "%2F")
        .replace(/\?/g, "%3F")
        .replace(/\#/g, "%23")
        .replace(/\&/g, "%26");
      }
      return url;
    }
    }
  }
</script>

<style scoped lang="scss">

  .el-header, .el-footer {
    line-height: 60px;
  }

  .el-aside {
    background-color: #D3DCE6;
  }

  .el-container {
    height: 100%;
  }

  .right-aside {
    .title {
      line-height: 50px;
      text-align: center;
      border-bottom: 1px #eee solid;
    }
  }

  .el-main.main ::v-deep {
    padding: 0;
    height: 100%;
    $editor-height: 200px;

    .message-record {
      box-sizing: border-box;
      background-color: white;
      height: calc(100% - #{$editor-height});
      overflow: auto;
      padding: 10px;

      .message-row {
        box-sizing: border-box;
        margin-bottom: 15px;

        &.self {
          text-align: right;

          .message-content {
            &:before {
              left: 100%;
              border: 5px solid transparent;
              border-left-color: #F8f8f8;
            }
          }
        }

        .message-content {
          box-sizing: border-box;
          padding: 0 10px;
          display: inline-block;
          line-height: 2.5;
          border: 1px #eee solid;
          background-color: #F8f8f8;
          border-radius: 3px;
          position: relative;
          margin-top: 5px;

          &:before {
            box-sizing: border-box;
            content: '';
            top: 10px;
            position: absolute;
            right: 100%;
            border: 6px solid transparent;
            border-right-color: #F8f8f8;
          }
        }
      }
    }

    .el-tiptap-editor {
      height: $editor-height;
      border-radius: unset;

      .ProseMirror {
        min-height: 100%;
      }

      .el-tiptap-editor__menu-bar:before {
        margin: 0;
      }

      & > .el-tiptap-editor__content {
        padding: 10px;
        flex: 1;
      }

      .border-top-radius {
        border-radius: unset;
      }
    }
  }

  .iconft {
    font-size: 20px;
    padding-right: 10px;
  }

  .links {
    display: flex;
    background-color: white;
    flex-wrap: wrap;
  }

  .link {
    padding: 2px 10px;
    border: 1px solid;
    border-radius: 24px;
    margin: 2px 2px 5px 5px;
    color: #969798;
  }
  .el-form-item {
    margin-bottom: 20px;
  }
  .el-dialog__body {
    padding: 10px 10px;
  }
  .el-form-item__content {
    margin-left: 0px !important;
  }
</style>
